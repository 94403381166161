
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";
// import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import StatisticsWidget5 from "@/components/widgets/statsistics/Widget5.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import autoreponders from "@/core/data/autoresponderkeywords";
import { ICustomer } from "@/core/data/customers";
import transactionHistory from "@/core/data/transactionHistory";
import { ITransactionHistory } from "@/core/data/transactionHistory";

import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
//import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default defineComponent({
  name: "customers-listing",
  components: {
    //Datatable,
    // ExportCustomerModal,
    // AddCustomerModal,
    StatisticsWidget5,
    VPagination,
  },
  setup() {
    const store = useStore();
    const showTable = ref(false);
    const tableHeader = ref([
      // {
      //   key: "checkbox",
      //   sortable: false,
      // },
      {
        name: "Tx ID",
        key: "payment_id",
        sortable: true,
      },
      {
        name: "Amount",
        key: "amount",
        sortable: true,
      },
      {
        name: "createdAt",
        key: "createdAt",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<any>>([]);
    const initCustomers = ref<Array<any>>([]);

    const limit = ref(10);
    const total_pages = ref(1);

    const activePlan = ref([]);
    const dataOfCount = ref("");
    const currentUser = ref(null);

    onMounted(() => {
      setCurrentPageBreadcrumbs("My Plan", []);
      getItem();
      getPaymentHistory(1);
      const data = localStorage.getItem("userdata");
      if (data) {
        var allData = JSON.parse("" + data + "");
        dataOfCount.value = allData;
        getUser(allData._id);
      }
    });

    const getUser = (id) => {
      var request = {
        url: `/oneuser/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          currentUser.value = data.data;
        }
      });
    };

    const dateTime = (value) => {
      return moment(value).format("YYYY-MM-DD");
    };

    const getPaymentHistory = (page) => {
      var request = {
        url: `/transaction-list?skip=${page}&limit=${limit.value}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          tableData.value = data.data;
          console.log(tableData.value);
          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );
          total_pages.value = Math.ceil(data.total / limit.value);
          console.log(total_pages.value);
          showTable.value = true;
        }
      });
    };

    const updateHandler = (value) => {
      showTable.value = false;
      getPaymentHistory(value);
    };

    const getItem = () => {
      var request = {
        url: "/active-plan-list",
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          activePlan.value = data.data;
          // console.log('datata',tableData.value);
        }
      });
    };
    const unsubscribe = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You Want To Un-Subscription This Plan,You Will Not Be Able To Recover This Plans Numbers and all the Information!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          // for (let i = 0; i < tableData.value.length; i++) {
          //   if (tableData.value[i].id === id) {
          //     tableData.value.splice(i, 1);
          //   }
          // }
          var request = {
            url: `mainunsubscribe/${id}`,
          };
          store.dispatch(Actions.GET, request).then((data) => {
            if (data) {
              Swal.fire(
                "Un-Subscription!",
                "Un-Subscription successfully!",
                "success"
              );
              getItem();
            }
          });
          // Swal.fire("Deleted!", "Tags has been deleted.", "success");
        }
      });
    };

    return {
      tableData,
      tableHeader,
      //   deleteFewAutoresonder,
      //   search,
      //   searchItems,
      // checkedAutoresponderKeyword,
      //   deleteAutoresponderKeyword,
      //   dateTime,
      showTable,
      activePlan,
      unsubscribe,
      dataOfCount,
      currentUser,
      updateHandler,
      limit,
      total_pages,
      dateTime,
    };
  },
});
